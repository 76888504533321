import React, { useState, useEffect, useContext } from 'react';
import './LongevityRecommendations.css';
import { AuthContext } from "../App";

const LongevityRecommendations = () => {
const apiUrl = process.env.REACT_APP_API_URL || '';
  const { setIsAuthenticated } = useContext(AuthContext);
  const [facilityData, setFacilityData] = useState([]);
  const [supplementData, setSupplementData] = useState([]);
  const [advice, setAdvice] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRecommendations = async (latitude, longitude) => {
      try {
        const response = await fetch(
          apiUrl + '/api/recommendations',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ lat: latitude, lon: longitude }),
          }
        );
        const data = await response.json();
        setFacilityData(data.facility_data || []);
        setSupplementData(data.supplement_data || []);
        setAdvice(data.advice || '');
      } catch (error) {
        console.error('Error fetching recommendations:', error);
      } finally {
        setLoading(false);
      }
    };

    const getUserLocationAndFetchRecommendations = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            fetchRecommendations(latitude, longitude);
          },
          (error) => {
            console.error('Error getting location:', error);
            setLoading(false);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
        setLoading(false);
      }
    };

    getUserLocationAndFetchRecommendations();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="health-recommendations">
      <h3>Longevity Recommendations</h3>
      <div>{advice}</div>

      {facilityData.length > 0 && (
        <>
          <h4>Recommended Wellness Facilities</h4>
          <div class="table-container">
          <table className="recommendation-table">
            <thead>
              <tr>
                <th>Logo</th>
                <th>Name</th>
                <th>Description</th>
                <th>Contact</th>
                <th>Website</th>
              </tr>
            </thead>
            <tbody>
              {facilityData.map((facility, index) => (
                <tr key={index}>
                  <td>
                    {facility.logo && <img src={facility.logo} alt={facility.name} className="facility-logo" />}
                  </td>
                  <td><strong>{facility.name}</strong></td>
                  <td>{facility.description}</td>
                  <td>
                    {facility.address && (
                      <div>
                        Address: <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(facility.name + ' ' + facility.address)}`} target="_blank" rel="noopener noreferrer">
                          {facility.address}
                        </a>
                      </div>
                    )}
                    {facility.phone && (
                      <div>
                        Phone: <a href={`tel:${facility.phone}`}>{facility.phone}</a>
                      </div>
                    )}
                    {facility.email && (
                      <div>
                        Email: <a href={`mailto:${facility.email}`}>{facility.email}</a>
                      </div>
                    )}
                  </td>
                  <td>
                    {facility.link && (
                      <a href={facility.link} target="_blank" rel="noopener noreferrer">Visit Website</a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table></div>
        </>
      )}

      {supplementData.length > 0 && (
        <>
          <h4>Recommended Supplements</h4>
          <div class="table-container">
          <table className="recommendation-table">
            <thead>
              <tr>
                <th>Photo</th>
                <th>Name</th>
                <th>Description</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              {supplementData.map((supplement, index) => (
                <tr key={index}>
                  <td>
                    {supplement.photo && <img src={supplement.photo} alt={supplement.name} className="supplement-photo" />}
                  </td>
                  <td><strong>{supplement.name}</strong></td>
                  <td>{supplement.description}</td>
                  <td>
                    {supplement.link && (
                      <a href={supplement.link} target="_blank" rel="noopener noreferrer">Buy Now</a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </>
      )}
    </div>
  );
};

export default LongevityRecommendations;
