import React, { useState, useEffect, useContext } from 'react';
import './DiaryScreen.css';
import Select from 'react-select'; // Import react-select for dropdowns
import { AuthContext } from "../App";

const DiaryScreen = () => {
  const apiUrl = process.env.REACT_APP_API_URL || '';
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const [diaryEntries, setDiaryEntries] = useState([]);
  const [newEntry, setNewEntry] = useState('');
  const [foodEntry, setFoodEntry] = useState('');
  const [selectedSupplements, setSelectedSupplements] = useState([]);
  const [selectedWellnessFacilities, setSelectedWellnessFacilities] = useState([]);
  const [supplements, setSupplements] = useState([]);
  const [wellnessFacilities, setWellnessFacilities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [weather, setWeather] = useState(null);

  // Fetch available supplements and wellness facilities
  const fetchOptions = async () => {
    try {
      const [supplementResponse, wellnessResponse] = await Promise.all([
        fetch(`${apiUrl}/api/supplements`, { method: 'GET', credentials: 'include' }),
        fetch(`${apiUrl}/api/wellness-facilities`, { method: 'GET', credentials: 'include' })
      ]);

      const supplementsData = await supplementResponse.json();
      const wellnessData = await wellnessResponse.json();

      setSupplements(supplementsData.map(item => ({ value: item.id, label: item.name })));
      setWellnessFacilities(wellnessData.map(item => ({ value: item.id, label: item.name })));
    } catch (error) {
      console.error('Error fetching supplements or wellness facilities:', error);
    }
  };

  const fetchDiaryEntries = async (page = 1) => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/diary-entries?page=${page}&per_page=10`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();
      setDiaryEntries(data.entries || []);
      setCurrentPage(data.current_page);
      setTotalPages(data.pages);
    } catch (error) {
      console.error('Error fetching diary entries:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchWeatherData = async (latitude, longitude) => {
    const apiKey = process.env.REACT_APP_WEATHER_API_KEY;
    const apiUrlw = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&units=metric&appid=${apiKey}`;

    try {
      const response = await fetch(apiUrlw);
      const data = await response.json();

      if (response.ok) {
        setWeather({
          temperature: data.main.temp,
          humidity: data.main.humidity,
          pressure: data.main.pressure,
        });
      } else {
        console.error('Failed to fetch weather data:', data);
      }
    } catch (error) {
      console.error('Error fetching weather data:', error);
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          setLatitude(lat);
          setLongitude(lon);

          fetchWeatherData(lat, lon);
        },
        (error) => {
          console.error("Error fetching location:", error);
        }
      );
    }
    fetchDiaryEntries(currentPage);
    fetchOptions(); // Fetch supplements and wellness facilities options
  }, [currentPage]);

  const hasEntryForToday = () => {
    const today = new Date().toDateString();
    return diaryEntries.some(entry => {
      const entryDate = new Date(entry.created_at + 'Z').toDateString();
      return entryDate === today;
    });
  };

const handleSubmit = async (e) => {
  e.preventDefault();

  if (hasEntryForToday()) {
    alert("You have already submitted an entry for today.");
    return;
  }

  if (!newEntry || !foodEntry) {
    alert("Feelings and food fields are required.");
    return;
  }

  // Start building the content string
  let content = `Feelings: ${newEntry}\n\nFood: ${foodEntry}`;

  // Conditionally add Supplements if selected
  if (selectedSupplements.length > 0) {
    content += `\n\nSupplements: ${selectedSupplements.map(supp => supp.label).join(', ')}`;
  }

  // Conditionally add Wellness Facilities if selected
  if (selectedWellnessFacilities.length > 0) {
    content += `\n\nWellness facilities: ${selectedWellnessFacilities.map(wellness => wellness.label).join(', ')}`;
  }

  try {
    const response = await fetch(`${apiUrl}/api/diary-entries`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        content: content,
        temperature: weather?.temperature,
        humidity: weather?.humidity,
        pressure: weather?.pressure,
      }),
    });

    if (response.ok) {
      const savedEntry = await response.json();

      const entryWithWeatherData = {
        ...savedEntry,
        temperature: weather?.temperature,
        humidity: weather?.humidity,
        pressure: weather?.pressure,
      };

      setDiaryEntries([entryWithWeatherData, ...diaryEntries]);
      setNewEntry('');
      setFoodEntry('');
      setSelectedSupplements([]);
      setSelectedWellnessFacilities([]);

      if (diaryEntries.length >= 10) {
        fetchDiaryEntries(currentPage);
      } else {
        if (diaryEntries.length % 10 === 0) {
          setTotalPages(totalPages + 1);
        }
      }
    } else {
      console.error('Failed to save diary entry:', await response.text());
    }
  } catch (error) {
    console.error('Error saving diary entry:', error);
  }
};


  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="diary-screen">
      <h3>Your Diary</h3>
      <form onSubmit={handleSubmit} className="diary-form">
        <textarea
          value={newEntry}
          onChange={(e) => setNewEntry(e.target.value)}
          placeholder="How do you feel today?"
          rows="2"
          className="diary-input"
        ></textarea>
        <textarea
          value={foodEntry}
          onChange={(e) => setFoodEntry(e.target.value)}
          placeholder="What did you eat today?"
          rows="2"
          className="diary-input"
        ></textarea>

        {/* Multi-select dropdown for supplements */}
        <Select
          isMulti
          options={supplements}
          value={selectedSupplements}
          onChange={setSelectedSupplements}
          placeholder="Select supplements you took today"
          className="multi-select"
        />

        {/* Multi-select dropdown for wellness facilities */}
        <Select
          isMulti
          options={wellnessFacilities}
          value={selectedWellnessFacilities}
          onChange={setSelectedWellnessFacilities}
          placeholder="Select wellness facilities you visited today"
          className="multi-select"
        />

        <button type="submit" className="submit-button">Save Entry</button>
      </form>
      <div className="entries-list">
        {loading ? (
          <p>Loading...</p>
        ) : (
          diaryEntries.map((entry, index) => (
            <div key={index} className="diary-entry">
              <p dangerouslySetInnerHTML={{ __html: entry.content.replace(/\n/g, '<br />') }}></p>
              <small>{new Date(entry.created_at + 'Z').toLocaleString('en-GB')}</small>

              {/* Conditionally show weather data if available */}
              {entry.temperature !== null && (
                <div className="entry-weather">
                  <small>Outdoor air temperature: {entry.temperature}°C</small>
                  <small>Humidity: {entry.humidity}%</small>
                  <small>Air pressure: {entry.pressure} hPa</small>
                </div>
              )}
            </div>
          ))
        )}
      </div>
      {!loading && totalPages > 0 && (
        <div className="pagination">
          <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default DiaryScreen;
