import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns'; // Import the date-fns adapter
import './WhoopData.css';

const WhoopData = () => {
  const apiUrl = process.env.REACT_APP_API_URL || '';
  const [sleepData, setSleepData] = useState(null);
  const [recoveryData, setRecoveryData] = useState(null);
  const [whoopIntegrated, setWhoopIntegrated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Check if WHOOP is integrated
    fetch(apiUrl + '/api/whoop_token_exists', {
      method: 'GET',
      credentials: 'include', // Include cookies for session management
    })
      .then((response) => response.json())
      .then((data) => {
        setWhoopIntegrated(data.exists);

        // Fetch sleep data
        const fetchSleepData = fetch(apiUrl + '/api/sleep-data', {
          method: 'GET',
          credentials: 'include',
        }).then((response) => response.json());

        // Fetch recovery data
        const fetchRecoveryData = fetch(apiUrl + '/api/recovery-data', {
          method: 'GET',
          credentials: 'include',
        }).then((response) => response.json());

        // Wait for both data fetches to complete
        return Promise.all([fetchSleepData, fetchRecoveryData]);
      })
      .then(([sleepData, recoveryData]) => {
        setSleepData(sleepData);
        setRecoveryData(recoveryData);
      })
      .catch((error) => console.error('Error:', error))
      .finally(() => setLoading(false)); // Stop loading once everything is done
  }, []);

const renderChart = (
  yData,
  yDataBetterProjections,
  yDataWorseProjections,
  xData,
  xDataProjections,
  title
) => {
  // Find the last valid historical data point (not null)
  const lastValidIndex = yData.map((value, index) => (value !== null ? index : null))
                              .filter(index => index !== null)
                              .pop();

  // Get the last valid yData value and corresponding xData value
  const lastValidYPoint = yData[lastValidIndex];
  const lastValidXPoint = xData[lastValidIndex];

  // Combine last valid historical point with projection yData and xData
  const combinedBetterYData = [lastValidYPoint, ...yDataBetterProjections];
  const combinedWorseYData = [lastValidYPoint, ...yDataWorseProjections];
  const combinedXData = [lastValidXPoint, ...xDataProjections];

  // Create separate datasets for historical and projected data (better and worse)
  const chartData = {
    labels: xData, // Use historical x-data for the main labels
    datasets: [
      {
        label: 'Historical',
        data: yData,
        borderColor: '#8884d8', // Stroke color for historical line
        fill: false,
        tension: 0.1,
        spanGaps: true, // Connect points even if there are missing data points
      },
      {
        label: "Following Aion's Recommendations",
        borderColor: '#4CAF50', // Green for better projections
        fill: false,
        borderDash: [5, 5],
        tension: 0.1,
        spanGaps: true, // Connect points even if there are missing data points
        data: combinedXData.map((x, index) => ({
          x,
          y: combinedBetterYData[index],
        })),
      },
      {
        label: "Not Following Aion's Recommendations",
        borderColor: '#FF5733', // Red for worse projections
        fill: false,
        borderDash: [5, 5],
        tension: 0.1,
        spanGaps: true, // Connect points even if there are missing data points
        data: combinedXData.map((x, index) => ({
          x,
          y: combinedWorseYData[index],
        })),
      },
    ],
  };

  // Chart.js options
  const chartOptions = {
    scales: {
      x: {
        type: 'time', // Enable time scaling on x-axis
        time: {
          unit: 'day', // Adjust the time unit if necessary (e.g., 'hour', 'month', etc.)
          tooltipFormat: 'd MMM yyyy', // Format used for the tooltips
          displayFormats: {
            day: 'd MMM yyyy', // Format used for the x-axis labels
          },
        },
        title: {
          display: true,
          text: 'Time',
        },
      },
      y: {
        title: {
          display: true,
          text: title,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        filter: (tooltipItem) => {
          const dataIndex = tooltipItem.dataIndex;
          const datasetLabel = tooltipItem.dataset.label;

          // Show only the historical tooltip at the joint point
          if (dataIndex === lastValidIndex && datasetLabel === 'Historical') {
            return true; // Show tooltip for historical data at the joint point
          }

          // Hide tooltips for projection datasets at the joint point
          const isProjection = datasetLabel.includes("Recommendations");
          if (isProjection && dataIndex === 0) {
            return false; // Hide tooltip for projections at the joint point
          }

          // Show tooltips for all other points
          return true;
        },
      },
    },
  };

  return (
    <div className="chart-container">
      <h4>{title}</h4>
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};



  const renderContent = () => {
    if (loading) {
      return <p>Loading...</p>;
    }

    if (!whoopIntegrated) {
      return <div className="message"><p>WHOOP is not integrated. Please integrate your WHOOP device to track sleep and recovery data.</p></div>;
    }

    if (!sleepData && !recoveryData) {
      return <div className="message"><p>No sleep or recovery data available. Please ensure your WHOOP device is collecting data.</p></div>;
    }

    return (
      <div className="charts">
        <h3>Sleep Data</h3>
        {renderChart(
          sleepData.historical_data.respiratory_rate, // Historical y-values for respiratory rate
          sleepData.projections.better.respiratory_rate, // Better projection y-values
          sleepData.projections.worse.respiratory_rate, // Worse projection y-values
          sleepData.historical_data.time, // Historical x-values (time)
          sleepData.projections.better.time, // Projected x-values (time)
          'Respiratory Rate' // Chart title
        )}
        {renderChart(
          sleepData.historical_data.sleep_performance_percentage,
          sleepData.projections.better.sleep_performance_percentage,
          sleepData.projections.worse.sleep_performance_percentage,
          sleepData.historical_data.time,
          sleepData.projections.better.time,
          'Sleep Performance'
        )}
        {renderChart(
          sleepData.historical_data.sleep_efficiency_percentage,
          sleepData.projections.better.sleep_efficiency_percentage,
          sleepData.projections.worse.sleep_efficiency_percentage,
          sleepData.historical_data.time,
          sleepData.projections.better.time,
          'Sleep Efficiency'
        )}
        {renderChart(
          sleepData.historical_data.sleep_consistency_percentage,
          sleepData.projections.better.sleep_consistency_percentage,
          sleepData.projections.worse.sleep_consistency_percentage,
          sleepData.historical_data.time,
          sleepData.projections.better.time,
          'Sleep Consistency'
        )}

        <h3>Recovery Data</h3>
        {renderChart(
          recoveryData.historical_data.recovery_score,
          recoveryData.projections.better.recovery_score,
          recoveryData.projections.worse.recovery_score,
          recoveryData.historical_data.time,
          recoveryData.projections.better.time,
          'Recovery Score'
        )}
        {renderChart(
          recoveryData.historical_data.resting_heart_rate,
          recoveryData.projections.better.resting_heart_rate,
          recoveryData.projections.worse.resting_heart_rate,
          recoveryData.historical_data.time,
          recoveryData.projections.better.time,
          'Resting Heart Rate'
        )}
        {renderChart(
          recoveryData.historical_data.hrv_rmssd_milli,
          recoveryData.projections.better.hrv_rmssd_milli,
          recoveryData.projections.worse.hrv_rmssd_milli,
          recoveryData.historical_data.time,
          recoveryData.projections.better.time,
          'HRV (RMSSD)'
        )}
        {renderChart(
          recoveryData.historical_data.spo2_percentage,
          recoveryData.projections.better.spo2_percentage,
          recoveryData.projections.worse.spo2_percentage,
          recoveryData.historical_data.time,
          recoveryData.projections.better.time,
          'SpO2 Percentage'
        )}
        {renderChart(
          recoveryData.historical_data.skin_temp_celsius,
          recoveryData.projections.better.skin_temp_celsius,
          recoveryData.projections.worse.skin_temp_celsius,
          recoveryData.historical_data.time,
          recoveryData.projections.better.time,
          'Skin Temperature (°C)'
        )}
      </div>
    );
  };

  return (
    <div className="data-monitoring">
      <h3>Whoop Data</h3>
      <p>Tracking your sleep and recovery metrics over time</p>
      {renderContent()}
    </div>
  );
};

export default WhoopData;
