import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './SettingsScreen.css';
import { AuthContext } from "../App";
import { TailSpin } from 'react-loader-spinner';

const SettingsScreen = () => {
  const apiUrl = process.env.REACT_APP_API_URL || '';

  const navigate = useNavigate();
  const location = useLocation();
  const { setIsAuthenticated } = useContext(AuthContext);
  const [whoopTokenExists, setWhoopTokenExists] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isStoringToken, setIsStoringToken] = useState(false);

  // State for user information and loading flag
  const [userInfo, setUserInfo] = useState({
    date_of_birth: '',
    height: '',
    weight: '',
    gender: ''
  });
  const [initialUserInfo, setInitialUserInfo] = useState({}); // Track the initial state
  const [isUserInfoLoading, setIsUserInfoLoading] = useState(true); // Loading flag for user data
  const [isSaving, setIsSaving] = useState(false);
  const [bmi, setBmi] = useState(null);
  const [bmiClassification, setBmiClassification] = useState('');
  const [customerId, setCustomerId] = useState(null); // Add state for customer ID

  // State for Apple Health file upload
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false); // Loading flag for file upload

  // Fetch Stripe Customer ID from the backend
  const fetchCustomerId = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/get-customer-id`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();
      setCustomerId(data.customer_id);  // Set the customer ID from the backend
    } catch (error) {
      console.error('Error fetching customer ID:', error);
    }
  };

  // Handle creation of the Stripe Customer Portal session
  const handleCreatePortalSession = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/create-portal-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ customer_id: customerId }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      window.location.href = data.url;  // Redirect to Stripe's Customer Portal
    } catch (error) {
      console.error('Error creating portal session:', error);
    }
  };

  const checkWhoopToken = async () => {
    try {
      const response = await fetch(apiUrl + '/api/whoop_token_exists', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.ok) {
        const data = await response.json();
        setWhoopTokenExists(data.exists);
      } else {
        console.error('Failed to check Whoop token existence');
      }
    } catch (error) {
      console.error('Error checking Whoop token existence:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserInfo = async () => {
    setIsUserInfoLoading(true); // Start loading user info
    try {
      const response = await fetch(apiUrl + '/api/get-user-info', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.ok) {
        const data = await response.json();
       const userInfoData = {
          date_of_birth: data.date_of_birth || '',
          height: data.height || '',
          weight: data.weight || '',
          gender: data.gender || '',
        };
        setUserInfo(userInfoData);
        setInitialUserInfo(userInfoData); // Set the initial state
        calculateBMI(data.height, data.weight); // Calculate BMI if data is present
      } else {
        console.error('Failed to fetch user information');
      }
    } catch (error) {
      console.error('Error fetching user information:', error);
    } finally {
      setIsUserInfoLoading(false); // End loading user info
    }
  };

  useEffect(() => {
    checkWhoopToken();
    fetchUserInfo();
    fetchCustomerId();  // Fetch customer ID when component mounts
  }, [location]);

  useEffect(() => {
    const handleTokenMessage = async (event) => {
      const tokenData = event.data;

      if (tokenData && tokenData.access_token && tokenData.refresh_token && tokenData.expires_in) {
        setIsStoringToken(true);
        try {
          const response = await fetch(apiUrl + '/api/store_token', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(tokenData)
          });

          if (response.ok) {
            console.log('Tokens stored successfully');
            setWhoopTokenExists(true);
          } else {
            console.error('Failed to store tokens:', await response.text());
            alert('Failed to store tokens. Please try again.');
          }
        } catch (error) {
          console.error('Error storing tokens:', error);
          alert('An error occurred while storing tokens. Please try again.');
        } finally {
          setIsStoringToken(false);
        }
      } else {
        console.error('Invalid token data received:', tokenData);
      }
    };

    window.addEventListener('message', handleTokenMessage);

    return () => {
      window.removeEventListener('message', handleTokenMessage);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === 'height' || name === 'weight') {
      const updatedHeight = name === 'height' ? value : userInfo.height;
      const updatedWeight = name === 'weight' ? value : userInfo.weight;
      calculateBMI(updatedHeight, updatedWeight);
    }
  };

  const calculateBMI = (height, weight) => {
    if (height > 0 && weight > 0) {
      const heightInMeters = height / 100; // Convert height to meters
      const calculatedBMI = (weight / (heightInMeters * heightInMeters)).toFixed(1); // Round BMI to one decimal place
      setBmi(calculatedBMI);
      classifyBMI(calculatedBMI);
    } else {
      setBmi(null);
      setBmiClassification('');
    }
  };

  const classifyBMI = (bmiValue) => {
    let classification = '';
    if (bmiValue < 18.5) {
      classification = 'Underweight';
    } else if (bmiValue >= 18.5 && bmiValue <= 24.9) {
      classification = 'Normal';
    } else if (bmiValue >= 25 && bmiValue <= 29.9) {
      classification = 'Overweight';
    } else if (bmiValue >= 30) {
      classification = 'Obesity';
    }
    setBmiClassification(classification);
  };

  const handleSaveUserInfo = async () => {
    setIsSaving(true);
    try {
      const response = await fetch(apiUrl + '/api/set-user-info', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(userInfo)
      });

      if (response.ok) {
        console.log('User information updated successfully.');
        setInitialUserInfo(userInfo); // Update the initial state after save
      } else {
        console.error('Failed to update user information');
        alert('Failed to update user information. Please try again.');
      }
    } catch (error) {
      console.error('Error updating user information:', error);
      alert('An error occurred while updating user information. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleIntegrateWhoop = async () => {
    try {
      const response = await fetch(apiUrl + '/api/get-whoop-authorization-url', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.ok) {
        const authorizationUrl = await response.text();
        window.open(
          authorizationUrl,
          'whoopAuthPopup',
          'width=500,height=600'
        );
      } else {
        console.error('Failed to get authorization URL');
        alert('Failed to get Whoop authorization URL. Please try again later.');
      }
    } catch (error) {
      console.error('Error during Whoop integration:', error);
      alert('An error occurred while trying to integrate your Whoop device. Please try again later.');
    }
  };

  const handleDeleteAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      try {
        const response = await fetch(apiUrl + '/api/delete-account', {
          method: 'DELETE',
          credentials: 'include',
        });

        if (response.ok) {
          alert('Your account has been deleted successfully.');
          navigate('/login');
        } else {
          console.error('Failed to delete account');
          alert('Failed to delete account. Please try again later.');
        }
      } catch (error) {
        console.error('Error during account deletion:', error);
        alert('An error occurred while trying to delete your account. Please try again later.');
      }
    }
  };

  // Handle file input change for Apple Health data upload
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  // Handle file upload
  const handleUploadAppleHealthData = async () => {
    if (!selectedFile) {
      alert("Please select a file first.");
      return;
    }

    setIsUploading(true);
    const formData = new FormData();
    formData.append('file', selectedFile); // Add the selected file to the form data

    try {
      const response = await fetch(apiUrl + '/api/upload-apple-health-data', {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });

      if (response.ok) {
        setTimeout(() => {
          alert('Apple Health data uploaded successfully.');
        }, 100); // Delay before showing success message
      } else {
        setTimeout(() => {
          alert('Failed to upload Apple Health data. Please try again.');
        }, 100); // Delay before showing failure message
      }
    } catch (error) {
      console.error('Error uploading Apple Health data:', error);
      setTimeout(() => {
        alert('An error occurred while uploading Apple Health data. Please try again.');
      }, 100); // Delay before showing error message
    } finally {
      setIsUploading(false);
    }
  };

    // Compare the current form values to the initial values to detect changes
  const isFormChanged = JSON.stringify(userInfo) !== JSON.stringify(initialUserInfo);


  return (
      <div className="settings-container">
        <div className="user-info-section">
          <h3>User Information</h3>
          {isUserInfoLoading ? (
              <p>Loading user information...</p>
          ) : (
              <>
                <div className="form-group">
                  <label>Date of Birth:</label>
                  <input
                      type="date"
                      name="date_of_birth"
                      value={userInfo.date_of_birth}
                      onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label>Height (cm):</label>
                  <input
                      type="number"
                      name="height"
                      value={userInfo.height}
                      onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label>Weight (kg):</label>
                  <input
                      type="number"
                      name="weight"
                      value={userInfo.weight}
                      onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label>Gender:</label>
                  <select
                      name="gender"
                      value={userInfo.gender}
                      onChange={handleInputChange}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                {bmi && (
                    <div
                        className={`bmi-display ${bmiClassification.includes('Normal') ? 'bmi-good' : 'bmi-bad'}`}>
                      <p>BMI: <strong>{bmi}</strong> ({bmiClassification})</p>
                    </div>
                )}
                <button
                    onClick={handleSaveUserInfo}
                    className={`save-button ${isFormChanged ? '' : 'disabled-button'}`}
                    disabled={isSaving || !isFormChanged}  // Disable if saving or form is unchanged
                >
                  {isSaving ? 'Saving...' : 'Save Information'}
                </button>
              </>
          )}
        </div>

        <div className="integration-section">
          <h3>Manage Subscription</h3>
          <p>View your subscription details or cancel your subscription via the
            Stripe Customer Portal.</p>
          <button onClick={handleCreatePortalSession}
                  className="integrate-button" disabled={!customerId}>
            Manage Subscription
          </button>
        </div>
        <div className="integration-section">
          <h3>Upload Apple Health Data</h3>
          <p>Upload your Apple Health data export (export.zip) to integrate
            health metrics.</p>
          <div className="file-upload-content">
          <input
              type="file"
              accept=".zip"
              onChange={handleFileChange}
              disabled={isUploading}
              className="file-input" // Keep the file input styling
          />
            </div>
          {isUploading ? ( // Show the spinner only when uploading
              <div className="loading-spinner">
                <TailSpin
                    height="50"
                    width="50"
                    color="#00BFFF"
                    ariaLabel="loading"
                />
              </div>
          ) : (
              <button
                  onClick={handleUploadAppleHealthData}
                  className="integrate-button"
                  disabled={isUploading || !selectedFile}
              >
                Upload Apple Health Data
              </button>
          )}
        </div>

        <div className="integration-section">
          <h3>Integrate Whoop Device</h3>
          <p>Integrate your Whoop device to track more detailed health metrics
            and enhance your experience.</p>
          {isLoading ? (
              <p>Loading...</p>
          ) : whoopTokenExists ? (
              <p className="integrated-message">Your Whoop device is already
                integrated.</p>
          ) : (
              <>
                {isStoringToken ? (
                    <div className="loading-spinner">
                      <TailSpin
                          height="50"
                          width="50"
                          color="#00BFFF"
                          ariaLabel="loading"
                      />
                      <p>Integrating...</p>
                    </div>
                ) : (
                    <button onClick={handleIntegrateWhoop}
                            className="integrate-button">
                      Integrate Whoop Device
                    </button>
                )}
              </>
          )}
        </div>

        <div className="integration-section coming-soon">
          <h3>Integrate Oura Ring</h3>
          <p>Integration with Oura Ring will be available soon.</p>
        </div>

        <div className="integration-section coming-soon">
          <h3>Integrate Muse Headband</h3>
          <p>Integration with Muse Headband will be available soon.</p>
        </div>

        <div className="integration-section coming-soon">
          <h3>Integrate Garmin Watch</h3>
          <p>Integration with Garmin watches will be available soon.</p>
        </div>

        <div className="integration-section coming-soon">
          <h3>Integrate Xiaomi Scale</h3>
          <p>Integration with Xiaomi scales will be available soon.</p>
        </div>

        <div className="delete-account-section">
          <h3>Delete Account</h3>
          <p>Warning: Deleting your account is irreversible. All your data will
            be permanently removed.</p>
          <button onClick={handleDeleteAccount}
                  className="delete-account-button">
            Delete Account
          </button>
        </div>
      </div>
  );
};

export default SettingsScreen;
