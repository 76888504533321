import React, { useState, useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner'; // Import TailSpin from react-loader-spinner
import './MedicalReportsData.css';

const MedicalReportsData = () => {
  const apiUrl = process.env.REACT_APP_API_URL || '';

  const [medicalReports, setMedicalReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false); // State for tracking file upload

  // Fetch medical reports data with pagination
  const fetchMedicalReports = async (page = 1) => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/medical-reports?page=${page}&per_page=10`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();
      setMedicalReports(data.reports || []);
      setCurrentPage(data.current_page);
      setTotalPages(data.pages);
    } catch (error) {
      window.alert('Error fetching medical reports! Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMedicalReports(currentPage); // Fetch reports on component mount and whenever page changes
  }, [currentPage]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!uploadedFile) {
      window.alert('Please select a file to upload.');
      return;
    }

    setIsUploading(true); // Show the spinner when file upload starts

    const formData = new FormData();
    formData.append('file', uploadedFile);

    try {
      const response = await fetch(apiUrl + '/api/upload-medical-report', {
        method: 'POST',
        body: formData,
        credentials: 'include',
      });

      const result = await response.json();

      // Remove spinner before showing the alert
      setIsUploading(false);

      // Allow state update to complete before blocking the UI with window.alert
      setTimeout(() => {
        if (response.ok) {
          fetchMedicalReports(currentPage); // Re-fetch reports after a successful upload
        } else {
          window.alert(`Error: ${result.message}`);
        }
      }, 100);
    } catch (error) {
      setIsUploading(false); // Ensure spinner is removed
      setTimeout(() => {
        window.alert('File upload failed. Please try again.');
      }, 0);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // Helper function to determine if a value is in the normal range
  const highlightValue = (value, lower, upper) => {
    if (value >= lower && value <= upper) {
      return <span style={{ color: '#28a745' }}>{value}</span>;
    } else {
      return <span style={{ color: '#dc3545' }}>{value}</span>;
    }
  };

  return (
    <div className="medical-reports-data">
      <h3>Your Medical Reports</h3>
      <div className="file-upload-section">
        {isUploading ? (
          <div className="loading-spinner">
            <TailSpin height="50" width="50" color="#00BFFF" ariaLabel="loading" />
            <p>Uploading and analyzing the file, please wait...</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="file-upload-content">
              <input type="file" onChange={handleFileUpload} />
            </div>
            <button type="submit" className="submit-button">Submit</button>
          </form>
        )}
      </div>

      <div className="reports-list">
        {loading ? (
          <p>Loading medical reports...</p>
        ) : (
          medicalReports.map((report, index) => (
            <div key={index} className="medical-report-entry">
              <p>
                Iron Level: {highlightValue(report.iron_level, 10, 30)} µmol/L
              </p>
              <p>
                Cholesterol Level: {highlightValue(report.cholesterol_level, 0, 5.2)} mmol/L
              </p>
              <small>{new Date(report.report_date).toLocaleDateString('en-GB')}</small>
            </div>
          ))
        )}
      </div>

      {!loading && totalPages > 0 && (
        <div className="pagination">
          <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default MedicalReportsData;
