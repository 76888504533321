import React, { useState, useContext, useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../App';
import { FcGoogle } from 'react-icons/fc';
import './LoginScreen.css';

const LoginScreen = () => {
  const apiUrl = process.env.REACT_APP_API_URL || '';
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Set isAuthenticated to false whenever the component is rendered
  useEffect(() => {
    setIsAuthenticated(false);
  }, [setIsAuthenticated]);

  // Google OAuth login
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const res = await fetch(apiUrl + '/authorize/google', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: tokenResponse.access_token }),
        credentials: 'include',
      });

      if (res.ok) {
        setIsAuthenticated(true);
        navigate('/dashboard');
      } else {
        const errorData = await res.json();
        window.alert(errorData.message || 'Google login failed');
      }
    },
    onError: () => {
      setIsAuthenticated(false);
      window.alert('Google login failed');
    },
  });

  // Email/password login
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(apiUrl + '/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
        credentials: 'include',
      });

      if (response.ok) {
        setIsAuthenticated(true);
        navigate('/dashboard');
      } else {
        const error = await response.json();
        window.alert(error.message || 'Login failed');
      }
    } catch (err) {
      window.alert('An error occurred during login');
    }
  };

  return (
      <div className="login-screen">
        <div className="login-container">
          <img src="/logo512.png" alt="Aion Longevity Logo"
               className="login-logo"/>
          <h1>Aion Longevity</h1>

          <form onSubmit={handleLogin}>
            <div className="form-group">
              <label>Email</label>
              <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
              />
            </div>
            <button type="submit">Log in</button>
          </form>

          <button onClick={() => googleLogin()}
                  className="google-login-button">
            <FcGoogle/> Log in with Google
          </button>

          <div className="additional-links">
            <p>Don't have an account? <Link to="/signup">Sign up</Link></p>
            <p><Link to="/forgot-password">Forgot your password?</Link></p>
          </div>
        </div>
      </div>
  );
};

export default LoginScreen;
