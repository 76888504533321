import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../App';
import WhoopData from './WhoopData';
import LongevityRecommendations from './LongevityRecommendations';
import NonWearableData from './NonWearableData';
import MedicalReportsData from './MedicalReportsData';
import SettingsScreen from './SettingsScreen';
import DiaryScreen from './DiaryScreen';
import AppleHealthData from './AppleHealthData';
import './Dashboard.css';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Dashboard = () => {
  const apiUrl = process.env.REACT_APP_API_URL || '';
  const navigate = useNavigate();
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const [userName, setUserName] = useState('');
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isProfileComplete, setIsProfileComplete] = useState(false);

  const [activeMainTab, setActiveMainTab] = useState(localStorage.getItem('activeMainTab') || 'diary');
  const [activeSubTab, setActiveSubTab] = useState(localStorage.getItem('activeSubTab') || 'whoop');
  const [activeCaptureSubTab, setActiveCaptureSubTab] = useState(localStorage.getItem('activeCaptureSubTab') || 'capture-photo');
  const [localDateTime, setLocalDateTime] = useState({
    date: new Date().toLocaleDateString('en-GB'),
    time: new Date().toLocaleTimeString(),
  });

  // Update the local time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setLocalDateTime({
        date: new Date().toLocaleDateString('en-GB'),
        time: new Date().toLocaleTimeString('en-GB'),
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, []);


  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    } else {
      checkSubscriptionStatus();
    }
  }, [isAuthenticated, navigate]);

  const checkSubscriptionStatus = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/check-subscription-status`, {
        method: 'GET',
        credentials: 'include',
      });

      const data = await response.json();
      if (data.subscription_active) {
        setSubscriptionActive(true);
        await checkProfileCompletion();
        fetchUserData();
      } else {
        redirectToStripeCheckout();
      }
    } catch (error) {
      console.error('Error checking subscription status:', error);
      navigate('/login');
    } finally {
      setLoading(false);
    }
  };

  const checkProfileCompletion = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/get-user-info`, {
        method: 'GET',
        credentials: 'include',
      });

      const data = await response.json();

      if (data.date_of_birth && data.gender && data.height && data.weight) {
        setIsProfileComplete(true);
      } else {
        setIsProfileComplete(false);
        navigate('/complete-profile');
      }
    } catch (error) {
      console.error('Error checking profile completion:', error);
      setIsAuthenticated(false);
      localStorage.removeItem('isAuthenticated');
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/user`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUserName(data.email);
      } else {
        setIsAuthenticated(false);
        localStorage.clear();
      }
    } catch (error) {
      setIsAuthenticated(false);
      localStorage.clear();
      console.error('Error fetching user data:', error);
    }
  };

  const redirectToStripeCheckout = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      const session = await response.json();
      const stripe = await stripePromise;

      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (error) {
        console.error('Stripe Checkout error:', error);
      }
    } catch (error) {
      console.error('Error during Stripe checkout:', error);
    }
  };

  const handleTabClick = (tabName) => {
    setActiveMainTab(tabName);
    localStorage.setItem('activeMainTab', tabName);
  };

  const handleSubTabClick = (subTabName) => {
    setActiveSubTab(subTabName);
    localStorage.setItem('activeSubTab', subTabName);
  };

  const handleCaptureSubTabClick = (captureSubTabName) => {
    setActiveCaptureSubTab(captureSubTabName);
    localStorage.setItem('activeCaptureSubTab', captureSubTabName);
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/logout`, {
        method: 'POST',
        credentials: 'include',
      });

      if (response.ok) {
        localStorage.clear();
        setIsAuthenticated(false);
        navigate('/login');
      } else {
        localStorage.clear();
        setIsAuthenticated(false);
        console.error('Failed to log out');
      }
    } catch (error) {
      localStorage.clear();
      setIsAuthenticated(false);
      console.error('Error during logout:', error);
    }
  };

  const renderSubContent = () => {
    switch (activeSubTab) {
      case 'apple-health':
        return <AppleHealthData />;
      case 'whoop':
        return <WhoopData />;
      case 'oura':
        return <p>Oura Ring data will be available here.</p>;
      case 'muse':
        return <p>Muse Headband data will be available here.</p>;
      case 'garmin':
        return <p>Garmin Watch data will be available here.</p>;
      case 'xiaomi':
        return <p>Xiaomi Scale data will be available here.</p>;
      default:
        return <p>Select a device to view data.</p>;
    }
  };

  const renderCaptureSubContent = () => {
    return <NonWearableData activeCaptureSubTab={activeCaptureSubTab} />;
  };

const renderMainContent = () => {
  switch (activeMainTab) {
    case 'diary':
      return <DiaryScreen />;
    case 'wearable':
      return (
        <div className="sub-tab-container">
          <div className="sub-tab-buttons">
            <button
              onClick={() => handleSubTabClick('apple-health')}
              className={activeSubTab === 'apple-health' ? 'active' : ''}
            >
              Apple Health
            </button>
            <button
              onClick={() => handleSubTabClick('whoop')}
              className={activeSubTab === 'whoop' ? 'active' : ''}
            >
              Whoop
            </button>
            <button
              onClick={() => handleSubTabClick('oura')}
              className={activeSubTab === 'oura' ? 'active' : ''}
            >
              Oura Ring
            </button>
            <button
              onClick={() => handleSubTabClick('muse')}
              className={activeSubTab === 'muse' ? 'active' : ''}
            >
              Muse Headband
            </button>
            <button
              onClick={() => handleSubTabClick('garmin')}
              className={activeSubTab === 'garmin' ? 'active' : ''}
            >
              Garmin Watch
            </button>
            <button
              onClick={() => handleSubTabClick('xiaomi')}
              className={activeSubTab === 'xiaomi' ? 'active' : ''}
            >
              Xiaomi Scale
            </button>
          </div>
          <div className="sub-tab-content">
            {renderSubContent()} {/* Render content below buttons */}
          </div>
        </div>
      );
    case 'capture':
      return (
        <div className="sub-tab-container">
          <div className="sub-tab-buttons">
            <button
              onClick={() => handleCaptureSubTabClick('capture-photo')}
              className={activeCaptureSubTab === 'capture-photo' ? 'active' : ''}
            >
              Capture Photo
            </button>
            <button
              onClick={() => handleCaptureSubTabClick('file-upload')}
              className={activeCaptureSubTab === 'file-upload' ? 'active' : ''}
            >
              Upload File
            </button>
          </div>
          <div className="sub-tab-content">
            {renderCaptureSubContent()} {/* Render content below buttons */}
          </div>
        </div>
      );
    case 'upload':
      return <MedicalReportsData />;
    case 'recommendations':
      return <LongevityRecommendations />;
    case 'settings':
      return <SettingsScreen />;
    default:
      return <WhoopData />;
  }
};


  if (loading) {
    return <div>Loading...</div>;
  }

  if (!subscriptionActive) {
    return <div>Redirecting to Stripe checkout...</div>;
  }

  return (
      <div className="dashboard">
        <div className="dashboard-header">
          <div className="dashboard-header-left">
            <button onClick={() => handleTabClick('diary')}
                    className={activeMainTab === 'diary' ? 'active' : ''}>
              Diary
            </button>
            <button onClick={() => handleTabClick('wearable')}
                    className={activeMainTab === 'wearable' ? 'active' : ''}>
              Wearable Devices Data
            </button>
            <button onClick={() => handleTabClick('capture')}
                    className={activeMainTab === 'capture' ? 'active' : ''}>
              Non-wearable Devices Data
            </button>
            <button onClick={() => handleTabClick('upload')}
                    className={activeMainTab === 'upload' ? 'active' : ''}>
              Medical Reports Data
            </button>
            <button onClick={() => handleTabClick('recommendations')}
                    className={activeMainTab === 'recommendations' ? 'active' : ''}>
              Longevity Recommendations
            </button>
          </div>
          <div className="dashboard-header-right">
            <span
                className="local-time">Local Date: {localDateTime.date}</span>
            <span
                className="local-time">Local Time: {localDateTime.time}</span>
            <span className="user-email">User: {userName}</span>
            <button onClick={() => handleTabClick('settings')}
                    className={activeMainTab === 'settings' ? 'active' : ''}>
              Settings
            </button>
            <button onClick={handleLogout} className="logout-button">Logout
            </button>
          </div>
        </div>
        <div className="dashboard-content">{renderMainContent()}</div>
      </div>
  );
};

export default Dashboard;
