import React, { useRef, useState } from 'react';
import './NonWearableData.css';

const NonWearableData = ({ activeCaptureSubTab }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [error, setError] = useState('');

  const startCamera = async () => {
    try {
      setPhoto(null);
      setIsCameraActive(true);
      setError('');
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
    } catch (err) {
      setIsCameraActive(false);
      setError('Camera access denied. Please enable camera permissions in your browser settings.');
    }
  };

  const takePhoto = () => {
    setPhoto(null);
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const video = videoRef.current;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const dataURL = canvas.toDataURL('image/png');
    setPhoto(dataURL);
    setIsCameraActive(false);
    video.srcObject.getTracks().forEach(track => track.stop());
    window.alert('Image captured but not uploaded and analyzed as that' +
        ' feature is not available yet.');
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFile(file);
    }
  };

  const handleFileSubmit = () => {
    if (uploadedFile) {
      window.alert(`File ${uploadedFile.name} is ready to be uploaded, but upload feature is not available yet.`);
    } else {
      window.alert('No file selected for upload.');
    }
  };

  return (
    <div className="capture-health-data">
      {activeCaptureSubTab === 'capture-photo' && (
        <div className="camera-section">
          <h3>Capture Data</h3>
          {isCameraActive ? (
            <div className="camera-content">
              <video ref={videoRef} autoPlay className="video-feed"></video>
              <div className="button-container">
                <button onClick={takePhoto} className="take-photo-button">
                  Take Photo
                </button>
              </div>
            </div>
          ) : (
            <div className="button-container">
              <button onClick={startCamera} className="start-camera-button">
                Start Camera
              </button>
            </div>
          )}
          {photo && (
            <div className="photo-preview">
              <h4>Photo Preview</h4>
              <img src={photo} alt="Captured" />
            </div>
          )}
        </div>
      )}

      {activeCaptureSubTab === 'file-upload' && (
        <div className="file-upload-content">
          <h3>Upload Data</h3>
          <input type="file" onChange={handleFileChange} />
          {uploadedFile && (
            <p>File selected: {uploadedFile.name}</p>
          )}
          <button onClick={handleFileSubmit} className="submit-file-button">
            Submit File
          </button>
        </div>
      )}

      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
    </div>
  );
};

export default NonWearableData;
