import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../App';
import './CompleteProfile.css';

const CompleteProfile = () => {
  const apiUrl = process.env.REACT_APP_API_URL || '';

  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState({
    date_of_birth: '',
    gender: '',
    height: '',
    weight: '',
  });
  const [error, setError] = useState('');

  // Redirect to login if not authenticated
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { date_of_birth, gender, height, weight } = userInfo;

    if (!date_of_birth || !gender || !height || !weight) {
      setError('Please fill in all fields');
      return;
    }

    try {
      const response = await fetch(apiUrl + '/api/set-user-info', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(userInfo),
      });

      const data = await response.json();

      if (response.ok) {
        setIsAuthenticated(true);
        localStorage.setItem('isProfileComplete', 'true');
        navigate('/dashboard');
      } else {
        setError('Failed to complete profile. Please try again.');
        console.error('Error:', data);
      }
    } catch (error) {
      console.error('Error completing profile:', error);
      setError('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div className="complete-profile-container">
      <div className="user-info-section">
        <h3>Complete Your Profile</h3>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Date of Birth:</label>
            <input
              type="date"
              name="date_of_birth"
              value={userInfo.date_of_birth}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Height (cm):</label>
            <input
              type="number"
              name="height"
              value={userInfo.height}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Weight (kg):</label>
            <input
              type="number"
              name="weight"
              value={userInfo.weight}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Gender:</label>
            <select
              name="gender"
              value={userInfo.gender}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
          <button type="submit" className="save-button">
            Save Profile
          </button>
        </form>
      </div>
    </div>
  );
};

export default CompleteProfile;
