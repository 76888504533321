import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns'; // Import the date-fns adapter
import './AppleHealthData.css';

const AppleHealthData = () => {
  const apiUrl = process.env.REACT_APP_API_URL || '';
  const [stepsData, setStepsData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch Apple Health steps data
    fetch(apiUrl + '/api/steps-data', {
      method: 'GET',
      credentials: 'include', // Include cookies for session management
    })
      .then((response) => response.json())
      .then((data) => {
        setStepsData(data);
      })
      .catch((error) => console.error('Error fetching steps data:', error))
      .finally(() => setLoading(false)); // Stop loading once the request is done
  }, [apiUrl]);

  const renderChart = (
    yData,
    yDataBetterProjections,
    yDataWorseProjections,
    xData,
    xDataProjections,
    title
  ) => {
    // Find the last valid historical data point (not null)
    const lastValidIndex = yData.map((value, index) => (value !== null ? index : null))
                                .filter(index => index !== null)
                                .pop();

    // Get the last valid yData value and corresponding xData value
    const lastValidYPoint = yData[lastValidIndex];
    const lastValidXPoint = xData[lastValidIndex];

    // Combine last valid historical point with projection yData and xData
    const combinedBetterYData = [lastValidYPoint, ...yDataBetterProjections];
    const combinedWorseYData = [lastValidYPoint, ...yDataWorseProjections];
    const combinedXData = [lastValidXPoint, ...xDataProjections];

    // Create separate datasets for historical and projected data (better and worse)
    const chartData = {
      labels: xData, // Use historical x-data for the main labels
      datasets: [
        {
          label: 'Historical Steps',
          data: yData,
          borderColor: '#8884d8', // Stroke color for historical line
          fill: false,
          tension: 0.1,
          spanGaps: true, // Connect points even if there are missing data points
        },
        {
          label: "Following Aion's Recommendations",
          borderColor: '#4CAF50', // Green for better projections
          fill: false,
          borderDash: [5, 5],
          tension: 0.1,
          spanGaps: true, // Connect points even if there are missing data points
          data: combinedXData.map((x, index) => ({
            x,
            y: combinedBetterYData[index],
          })),
        },
        {
          label: "Not Following Aion's Recommendations",
          borderColor: '#FF5733', // Red for worse projections
          fill: false,
          borderDash: [5, 5],
          tension: 0.1,
          spanGaps: true, // Connect points even if there are missing data points
          data: combinedXData.map((x, index) => ({
            x,
            y: combinedWorseYData[index],
          })),
        },
      ],
    };

    // Chart.js options
    const chartOptions = {
      scales: {
        x: {
          type: 'time', // Enable time scaling on x-axis
          time: {
            unit: 'day', // Adjust the time unit if necessary (e.g., 'hour', 'month', etc.)
            tooltipFormat: 'd MMM yyyy', // Format used for the tooltips
            displayFormats: {
              day: 'd MMM yyyy', // Format used for the x-axis labels
            },
          },
          title: {
            display: true,
            text: 'Time',
          },
        },
        y: {
          title: {
            display: true,
            text: title,
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          position: 'top',
        },
        tooltip: {
          filter: (tooltipItem) => {
            const dataIndex = tooltipItem.dataIndex;
            const datasetLabel = tooltipItem.dataset.label;

            // Show only the historical tooltip at the joint point
            if (dataIndex === lastValidIndex && datasetLabel === 'Historical Steps') {
              return true; // Show tooltip for historical data at the joint point
            }

            // Hide tooltips for projection datasets at the joint point
            const isProjection = datasetLabel.includes("Recommendations");
            if (isProjection && dataIndex === 0) {
              return false; // Hide tooltip for projections at the joint point
            }

            // Show tooltips for all other points
            return true;
          },
        },
      },
    };

    return (
      <div className="chart-container">
        <h4>{title}</h4>
        <Line data={chartData} options={chartOptions} />
      </div>
    );
  };

  const renderContent = () => {
    if (loading) {
      return <p>Loading...</p>;
    }

    if (!stepsData.historical_data || !stepsData.projections) {
      return <div className="message"><p>No steps data available. Please ensure Apple Health data is synced.</p></div>;
    }

    return (
      <div className="charts">
        <h3>Steps Data</h3>
        {renderChart(
          stepsData.historical_data.total_steps, // Historical steps data
          stepsData.projections.better.total_steps, // Better projection steps data
          stepsData.projections.worse.total_steps, // Worse projection steps data
          stepsData.historical_data.time, // Historical time data
          stepsData.projections.better.time, // Projected time data
          'Total Steps' // Chart title
        )}
      </div>
    );
  };

  return (
    <div className="data-monitoring">
      <h3>Apple Health Data</h3>
      <p>Tracking your step metrics over time</p>
      {renderContent()}
    </div>
  );
};

export default AppleHealthData;
